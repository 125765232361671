import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Landing } from "./Components/Landing/Landing";
//import Something from "@components/Something";
import LearningBacklog from "./Components/Learning Backlog/learningBacklog";

import "./App.css";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/learning-backlog" element={<LearningBacklog />} />
      </Routes>
    </Router>
  );
}

export default App;
