import React from "react";

const Landing = () => {
  return (
    <div>
      <h1 className="text-3xl font-bold">Dylan's Website</h1>
    </div>
  );
};

export { Landing };
