import React from "react";

const LearningBacklog = () => {
  return (
    <div>
      <h1 className="text-3xl font-bold">Backlog</h1>
      <p>
        1) Top down and bottom up AI learning. Top down means building stuff
        with existing AI models (openAI, huggingface, running them locally
        versus hosting in the cloud etc). Bottom up means learning the maths,
        building the models myself.
      </p>
      <p>
        2) Building out the mental health app as my personal project. The idea
        is for this to both do MVP so I'm actually getting working software that
        does what I want quickly. But also expoloring deployment pipelines,
        researching and using best practices, integrating with other platforms
        and building towards an actual product that 'just works'. One of the
        first things I want to learn is event driven architecture.
      </p>
      <p>
        During this process I want to test out doing the same thing across
        multiple platforms to get more reps in and build a better idea around
        what's actually required / fundamental versus quirks with the specific
        tools implementation
      </p>
      <p>
        3) Learning CS fundamentals. This will initially be via Nand2Tetris and
        following the teach yourself CS cirriculum subsequently. I also want to
        spend time working through Red Pig Data book.
      </p>
      <p>
        4) I want this to ideally be a learning in public venture. Potentially
        setting up streaming. But at the very least publishing what I learn each
        day. Additionally I want to do a better job capturing the random minutia
        I pick up in my day to day as a consultant.
      </p>
    </div>
  );
};

export default LearningBacklog;
